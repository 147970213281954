import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginApi } from "../api/hitapi";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(0);

  useEffect(() => {
    if (!Cookies.get("ani")) {
      // window.location.href = '/otp';
      navigate("/user/Login");
    } else {
      // hit the api to check the user authentication....
      checkUserBilling();
    }
  }, [navigate, auth, loading]);

  const checkUserBilling = async () => {
    console.log("hit the api and check user billing...");
    try {
      const response = await LoginApi(Cookies.get("ani"));
      if (response?.data === 2) {
        console.log("user authenticated...");
        setLoading(false);
        setAuth(1);
      } else if (response?.data === 1) {
        setLoading(false);
        toast.error("Billing Pending");
        console.log("user authenticated false....");
        setTimeout(() => {
          navigate("/otp");
        }, 2000);
      } else {
        console.log("user authenticated false....");
        setLoading(false);
        toast.error("You are not subscriber pls subscribe first");
        setTimeout(() => {
          navigate("/otp");
        }, 2000);
      }
    } catch (err) {
      console.log("user authenticated false....");
      setLoading(false);
      toast.error(
        err?.response?.data?.message ||
          err?.data?.message ||
          err?.message ||
          err
      );
      setTimeout(() => {
        navigate("/otp");
      }, 2000);
    }
  };

  return <>{!loading && auth && children}</>;
};

export default Auth;
