import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  CheckRedeemPointApi,
  Checkuser,
  LoginApi,
  RedeemPointsApi,
} from "../api/hitapi";
import Cookies from "js-cookie";
import Loader from "./Loader";

const RedeemModal = ({ open, onClose }) => {
  const staticData = [
    { points: "2000 points", price: "200 rs" },
    { points: "1000 points", price: "100 rs" },
    { points: "500 points", price: "50 rs" },
  ];

  const [redeemData, setRedeemData] = useState([]);
  const [status, setStatus] = useState("pending");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      hitCheckRedeemApi();
    } 
  }, [open]);

  const hitCheckRedeemApi = async () => {
    try {
      setLoading(true);
      const data = {
        ani: Cookies.get("ani"),
        points: Cookies.get("point"),
      };
      const response = await CheckRedeemPointApi(data);
      setLoading(false);
      if (response?.data?.status == "No") {
        setStatus("Failed");
      } else {
        setStatus("Success");
        setRedeemData(response?.data?.result);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const handleRedeem = async (item) => {
    try {
      setLoading(true);
      const data = {
        ani: Cookies.get("ani"),
        points: item?.points,
      };
      const response = await RedeemPointsApi(data);
      const response2 = await Checkuser(Cookies.get("ani"));
      Cookies.set("point", response2?.data?.Points?.points);
      setLoading(false);
      if (response?.data == "Done") {
        setMessage("Your Points will be credited to your account shortly!");
      } else {
        setMessage("Some Error Occured!");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  if (!open) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[1000]"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="relative w-full max-w-lg bg-gradient-to-r from-red-700 to-black rounded-lg shadow-2xl p-8 transform scale-95 transition-transform duration-300 hover:scale-100 max-h-[80vh] overflow-y-auto"
      >
        <p
          className="absolute top-2 right-2 cursor-pointer text-xl text-white"
          onClick={onClose}
        >
          X
        </p>
        <div className="mt-8">
          {loading ? (
            <div className="w-full min-h-[300px] flex justify-center items-center">
              <Loader />
            </div>
          ) : status == "Failed" ? (
            <h2 className="text-4xl font-extrabold mb-6 text-center text-red-400 bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-yellow-500 animate-pulse">
              Failed You do not have enough points to Redeem!
            </h2>
          ) : message != "" ? (
            <h2 className="text-4xl font-extrabold mb-6 text-center text-red-400 bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-yellow-500 animate-pulse">
              {message}
            </h2>
          ) : (
            <>
              <h2 className="text-4xl font-extrabold mb-6 text-center text-red-400 bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-yellow-500 animate-pulse">
                Redeem Rewards
              </h2>
              <table className="min-w-full bg-gray-900 rounded-lg border border-gray-800">
                <thead>
                  <tr>
                    <th className="py-3 px-4 border-b bg-red-800 text-white text-lg">
                      Points
                    </th>
                    <th className="py-3 px-4 border-b bg-red-800 text-white text-lg">
                      Price
                    </th>
                    <th className="py-3 px-4 border-b bg-red-800 text-white text-lg">
                      Redeem
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {redeemData.map((item, index) => (
                    <tr
                      key={index}
                      className="transition-transform transform hover:scale-105"
                    >
                      <td className="py-3 px-4 border-b text-white">
                        {item?.points}
                      </td>
                      <td className="py-3 px-4 border-b text-green-500">
                        {item?.amount}
                      </td>
                      <td className="py-3 px-4 border-b">
                        <button
                          onClick={() => handleRedeem(item)}
                          className="px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-500 hover:shadow-xl transition-transform duration-300 transform hover:scale-110"
                        >
                          Redeem
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
