import axios from "axios";

// const baseApi = 'http://5.189.166.187:9981/Game360BackEndApi/';
//const baseApi='https://game360.bigcash.co.za/';
// const baseApi='http://localhost:3832/';
// const gameInfoApi = 'http://5.189.166.187:9981/Game360/'
const baseApi = `https://www.wheelbackend.wheel2fortune.com/`;
const Checkuser = async (ani) => {
  const parameter = { ani: ani };
  console.log("paramete", parameter);
  return await axios.post(baseApi + "check?ani=", parameter);
};

const Gameinfo = async () => {
  return await axios.get(baseApi + "gameInfo");
};

const Checkscore = async (ani, gameId) => {
  const param = { ani: ani, gameId: gameId };
  return await axios.post(baseApi + "checkPoint", param);
};

const Leader = async () => {
  return await axios.get(baseApi + "leader");
};

const GifApi = async () => {
  return await axios.get(baseApi + "gif");
};

const CheckScoreForScorePage = async (ani) => {
  const parameter = { ani: ani };
  console.log("paramete", parameter);
  return await axios.post(baseApi + "score", parameter);
};

const RedeemApi = async (ani) => {
  const parameter = { ani: ani };
  console.log("paramete", parameter);
  return await axios.post(baseApi + "redeem", parameter);
};

const EditName = async (ani, name, imageName) => {
  const parameter = { ani: ani, name: name, image: imageName };
  return await axios.post(baseApi + "editName", parameter);
};
const LoginApi = async (ani) => {
  return await axios.post(baseApi + "login", { ani: ani });
};
export {
  Checkuser,
  Gameinfo,
  Checkscore,
  Leader,
  GifApi,
  CheckScoreForScorePage,
  RedeemApi,
  EditName,
  LoginApi,
};

export const CheckRedeemPointApi = async (data) => {
  const response = await axios.post(`${baseApi}redeemPointCheck`, data);
  return response;
};

export const RedeemPointsApi = async (data) => {
  const response = await axios.post(`${baseApi}deductPoints`, data);
  return response;
};

// export const Checkuser = (ani) => {
//
//     const parameter = {ani:ani};
//        console.log("paramete",parameter);
//         return  axios.post(baseApi +"check?ani=",parameter);

// }
