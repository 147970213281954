import React, { useState } from "react";
import imageLog from "../images/subscribe-bg.jpg";
import Lottie from "react-lottie";
import lottieBall from "../images/Animation - 1713865735149.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginApi } from "../api/hitapi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Login = () => {
  const [msisdn, setMsisdn] = useState('');
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setLogs(null);

    try {
      const response = await LoginApi(msisdn);
      console.log("response", response.data);
      if (response.data===2) {
        setLogs(response.data);
        toast.success("Login successful");
        Cookies.set("ani",msisdn);
        setTimeout(() => {
          navigate("/?ani="+msisdn+"&serviceId=wof");
        }, 2000);
        
      } else if(response.data===1){
        setLogs([response.data]);
        toast.success("Billing Pending");
        //toast.info("No logs found");
      }
      else
      {
        setLogs([response.data]);
        toast.info("You are not subscriber pls subscribe first");
         // Redirect to OTP page after 2 seconds
      setTimeout(() => {
        navigate("/otp");
      }, 2000);
      }
    } catch (err) {
      setError('Failed to fetch logs. Please try again.');
      toast.error("Failed to fetch logs. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen flex items-center justify-center">
        <img
          className="absolute h-full object-cover w-full"
          src={imageLog}
          alt="Background"
        />
        <div className="bg-gray-100 lg:w-5/12 md:w-6/12 w-10/12 shadow-3xl rounded-xl relative drop-shadow-2xl mt-3">
          <div className="p-2">
            <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-8 md:p-8">
              <Lottie
                options={{
                  animationData: lottieBall,
                }}
                style={{ width: "70%", height: "auto" }}
              />
            </div>
            <div className="">
              <form className="py-6 mt-4 px-4 md:py-12 md:mt-16 md:p-4" onSubmit={handleSubmit}>
                <div className="md:text-3xl text-3xl font-bold text-center">
                  LOGIN
                </div>
                <div className="mb-6 mt-6">
                  <label htmlFor="number" className="block text-gray-800 font-bold">
                    Enter your number
                  </label>
                  <input
                    value={msisdn}
                    onChange={(e) => setMsisdn(e.target.value)}
                    type="number"
                    name="number"
                    id="number"
                    placeholder="Enter your number"
                    className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-gradient-to-b from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full rounded text-lg mt-6"
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
                {error && <p className="text-red-500 text-center mt-4">{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
