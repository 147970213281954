import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Homepage from "../component/Homepage";
import Profile from "../component/Profile";
import Score from "../component/Score";
import Leaderboard from "../component/Leaderboard";
import Points from "../component/Points";
import Redeem from "../component/Redeem";
import Index from "../component/Index";
import NotValid from "../component/NotValid";
import Allcategory from "../component/Allcategory";
import AllGame from "../component/AllGame";
import SubCategory from "../component/SubCategory";
import Test from "../component/Test";
import Otp from "../component/Otp";
import HeFlow from "../component/HeFlow";
import Login from "../component/Login.js";
import Auth from "../component/Auth.js";

const Routingfile = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Router>
        <Routes>
          <Route path="/user/Login" element={<Login />} exact={true} />
          <Route
            path="/"
            element={
              <Auth>
                <Index />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/home"
            element={
              <Auth>
                <Homepage />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Otp"
            element={
              <Auth>
                <Otp />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/he"
            element={
              <Auth>
                <HeFlow />
              </Auth>
            }
            exact={true}
          />
          {/* <Route path="/sub" component={<SubCategory />} exact={true} /> */}
          <Route
            path="/Profile"
            element={
              <Auth>
                <Profile />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Score"
            element={
              <Auth>
                <Score />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Leader"
            element={
              <Auth>
                <Leaderboard />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Points"
            element={
              <Auth>
                <Points />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Radeem"
            element={
              <Auth>
                <Redeem />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/notvalid"
            element={
              <Auth>
                <NotValid />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Allcategory"
            element={
              <Auth>
                <Allcategory />
              </Auth>
            }
            exact={true}
          />
          <Route
            path="/Allimages"
            element={
              <Auth>
                <AllGame />
              </Auth>
            }
            exact={true}
          />
          <Route path="/test" element={<Test />} exact={true} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
    </>
  );
};

export default Routingfile;
