export const images = [
    {
        id : 1,
        name : "avatar1",
        images : 'https://www.gameninja.in/html/games360/avatar1.jpg'
    },
    {
        id : 2,
        name : "avatar2",
        images : 'https://www.gameninja.in/html/games360/avatar2.jpg'
    },
    {
        id : 3,
        name : "avatar3",
        images : 'https://www.gameninja.in/html/games360/avatar3.jpg'
    },
    {
        id : 4,
        name : "avatar4",
        images : 'https://www.gameninja.in/html/games360/avatar4.jpg'
    }
]